let sessionId: string = '12345';

(() => {
  sessionId = crypto.randomUUID();
  try {
    localStorage.setItem('sessionId', sessionId);
  } catch (err) {
    console.log('Failed to set session ID:', err);
  }

  // eslint-disable-next-line
  (window as any).sessionId = sessionId;
})();
